import Vue from "vue";

const firebaseConfig = {
  apiKey: "AIzaSyCALswV4mX7DizHaahqnXrU9oEbR6ra-xM",
  authDomain: "bricolage-limited.firebaseapp.com",
  databaseURL:
    "https://bricolage-limited-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "bricolage-limited",
  storageBucket: "bricolage-limited.appspot.com",
  messagingSenderId: "122343019878",
  appId: "1:122343019878:web:3b0d14fb0e209a7fbbe4a7",
  measurementId: "G-XZCKSNVFCC",
};

Vue.mixin({
  data: function () {
    return {
      get companyName() {
        return "BRICOLAGE LIMITED"; //公司名稱
      },
      get regFormDomain() {
        return "https://bricolagltd.xyz/";
      },
    };
  },
});

//  溫馨提示：建議使用較淺的顏色
//  歡迎回來的背景顏色
const panelColor = "#e8f2fa";

//  溫馨提示：建議使用較深的顏色
//  側邊欄的背景顏色
const sideBarColor = "#000";

//        /||\
//       / || \
//      |------|
//      |  ||  |
//      |  ||  |
//      |  ||  |
//      |  ||  |
//       \ || /
//  不用理 \||/
//        /||\
//       / || \
export { firebaseConfig, panelColor, sideBarColor };
